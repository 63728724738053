import React, { useState } from "react";
import { graphql } from "gatsby";

import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "@components/pageMeta";
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";

const PageTemplate = ({ data: { page } }) => {
  const { pageModules, seo, reverseHeader, unpinnedHeader } = page || {};
  const [freeze, setFreeze] = useState(false);

  return (
    <Layout
      unpinnedHeader={unpinnedHeader}
      reverseHeader={reverseHeader}
      freeze={freeze}
    >
      <PageMeta {...seo} />
      {pageModules && <ModuleZone {...pageModules} setFreeze={setFreeze} />}
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityModularPage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
      reverseHeader
      unpinnedHeader
    }
  }
`;
